<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-10 10:32:57
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-05 19:57:45
-->
<template>
  <div class="">
    <a-modal title="选择地址" v-model="visible" width="70%" style="top: 8px" :footer="null" :maskClosable="false">
      <a-row>
        <a-col :md="8" v-for="item in tableData" :key="item.index">
          <a-card :title="`【${item.province}】${item.contacts}`" :class="addressId == item.id ? 'active' : ''" class="my_card" style="cursor: pointer"  @dblclick="toSubmit(item)">
            <a slot="extra">
              <a-tag v-if="item.flagDefault == true" color="#f50">默认</a-tag>
              <a v-else @click="onSetDefault(item.id)">设为默认</a>
            </a>
            <a slot="extra" @click="doDelAddress(item.id)" style="margin-left: 10px;"><a-icon type="delete" /></a >
            <a slot="extra" @click="doEditAddress(item)" style="margin-left: 10px;"><a-icon type="edit" /></a >
            <p>{{ item.contacts }} {{ item.phone }}</p >
            <p>{{ item.province }}{{ item.city }}{{ item.area }}{{ item.address }}</p>
          </a-card>
        </a-col>

        <a-col :md="8">
          <a-card class="my_card"  @click="onShowAddress">
            <p style="text-align: center;margin-top: 25px;"><a-icon type="plus" style="font-size: 50px"/></p>
            <p style="text-align: center;">新增地址</p>
          </a-card>
        </a-col>
      </a-row>


      <a-modal title="添加地址" v-model="addressVisible" style="top: 8px;" :width="800" @ok="onConfirm" okText="确定">
        <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
          <a-form-model-item label="收货人" prop="contacts">
            <a-input v-model="rowData.contacts" placeholder="收货人"></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系方式" prop="phone">
            <a-input v-model="rowData.phone" placeholder="客户联系方式"></a-input>
          </a-form-model-item>
          <a-form-model-item label="收货地址" prop="provinceId">
            <PCD
              ref="pcd"
              placeholder="请选择区域"
              :province.sync="rowData.province"
              :city.sync="rowData.city"
              :district.sync="rowData.area"
              :provinceId.sync="rowData.provinceId"
              :cityId.sync="rowData.cityId"
              :districtId.sync="rowData.areaId"
            >
            </PCD>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="address">
            <a-textarea v-model="rowData.address" placeholder="详细收货地址" :max-length="50"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 编辑地址 -->
      <EditAddressModal ref="EditAddressModal" @reload="getList"></EditAddressModal>

    </a-modal>
  </div>
</template>

<script>
import EditAddressModal from '@/views/order_info/components/EditAddressModal.vue'
import { mobile } from '@/utils/rules'

export default {
  name: 'check_address',
  data() {
    let checkArea = (rule, value, callback) => {
      console.log(rule,value)
      if(value){
        if (this.rowData.area == null || this.rowData.city == null || this.rowData.province == null) {
          console.log(this.rowData)
          return callback(new Error('请选择完整的省市区'));
        }else {
          callback();
        }
      }else {
        return callback(new Error('请选择收货地址'));
      }
    };
    return {
      visible: false,
      tableLoading: false,
      tableData: [],
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      selectedRowKeys: [],
      selectedRows: [],
      dealerId: '',
      addressId: '',
      addressVisible: false,
      rowData: {},
      // 表单验证
      formRule: {
        provinceId: [
          { required: true, validator: checkArea, trigger: 'change'}
        ],
        // provinceId: [{ required: true, message: '请选择收货地址', trigger: 'change' }],
        contacts: [{ required: true, message: '请输入收货人', trigger: 'blur' }],
        phone: [{ required: true, pattern: new RegExp(/^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16(2|[5-7]))|(17[0-8])|(18[0-9])|(19([0-3]|[5-9])))\d{8}$|^0\d{2,3}-?\d{7,8}$/), message: '请输入正确的客户联系方式', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细收货地址', trigger: 'blur' }],
      },
      handle: 'add'
    }
  },

  components: {
    EditAddressModal
  },

  created() {},

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },

  methods: {
    show(dealerId, addressId) {

      this.dealerId = dealerId
      this.addressId = addressId
      this.visible = true
      this.getList()
    },

    // 获取数据源
    getList() {
      this.axios.get(`/api/dealer/dealer/dealerInfo/selectDealerOther/${this.dealerId}?type=2`).then((res) => {
        this.tableData = res.body
        console.log(this.tableData)
      })
    },

    onShowAddress() {
      this.handle = 'add'
      this.rowData = {}
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
      this.addressVisible = true
    },

    // 点击选中
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    // 添加地址
    onConfirm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        let url = this.handle == 'add' ? '/api/dealer/address/dealerDeliveryAddress/add' : '/api/dealer/dealer/dealerInfo/deliveryAddress/addressUpdate'
        this.rowData.dealerId = this.dealerId
        this.axios.post(url, this.rowData).then((res) => {
          if (res.code) {
            this.addressVisible = false
            this.rowData = {}
            this.getList()
            this.$message.success(res.message)
          }
        })
      })
    },

    // 编辑地址
    doEditAddress(row){
      this.addressVisible = true
      this.handle = 'edit'
      this.$set(this.rowData, 'contacts', row.contacts)
      this.$set(this.rowData, 'phone', row.phone)
      this.$set(this.rowData, 'province', row.province)
      this.$set(this.rowData, 'provinceId', row.provinceId)
      this.$set(this.rowData, 'city', row.city)
      this.$set(this.rowData, 'cityId', row.cityId)
      this.$set(this.rowData, 'area', row.area)
      this.$set(this.rowData, 'areaId', row.areaId)
      this.$set(this.rowData, 'address', row.address)
      this.$set(this.rowData, 'id', row.id)
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
    },

    // 设置默认地址
    onSetDefault(id) {
      let params = {
        id, type: 2
      }
      this.axios.get(`/api/dealer/dealer/dealerInfo/setDefaultDealerOther`, {params}).then(res => {
        this.$message.success(res.message)
        this.getList()
      })
    },

    // 删除地址
    doDelAddress(id){
      const _this = this
      _this.$confirm({
        title: '警告',
        content: `确定要删除此地址？`,
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          let params = {
            id, type: 2
          }
          _this.axios.get(`/api/dealer/dealer/dealerInfo/deleteDealerOther`, {params}).then(res => {
            _this.$message.success(res.message)
            _this.getList()
          })
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    },

    toSubmit(row) {
      this.visible = false
      this.$emit('refresh', row)
    },

    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList()
    },
  },
}
</script>

<style lang='scss' scoped>
  .my_card{
    width:95%;
    min-height:180px;
    max-height:180px;
    margin-bottom: 10px;
    cursor:pointer;
  }
  .active {
    border: 1px solid red;
  }
</style>