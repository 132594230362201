<template>
  <div>
    <a-modal title="选择商品" v-model="visible" width="75%" :footer="null" style="top: 8px" :maskClosable="false">
      <div class="realtor">
        <a-form-model ref="searchData" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="8" :xxl="8" :sm="24">
              <a-form-model-item label="商品名称">
                <a-input v-model="searchData.name" placeholder="请输入商品名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="24">
              <a-form-model-item label="商品分类">
                <a-cascader
                  :options="categoryList"
                  :value="categorySelectData"
                  style="width: 100%"
                  :field-names="{ label: 'name', value: 'id', children: 'children' }"
                  placeholder="请选择"
                  @change="onCategoryChange"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="24">
              <a-form-model-item label="商品品牌" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
                <a-select v-model="searchData.brandId" placeholder="请选择商品品牌">
                  <a-select-option :value="item.id" v-for="(item, index) in proBrandList" :key="index">
                    {{ item.ch_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="24">
              <a-form-model-item label="型号">
                <a-input v-model="searchData.barCode" placeholder="请输入商品型号"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="24">
              <a-form-model-item label="D价">
                <a-input-number
                  placeholder=""
                  :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                  v-model="searchData.startPrice"
                  :min="1"
                  :max="999999"
                />~
                <a-input-number
                  placeholder=""
                  :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                  v-model="searchData.endPrice"
                  :min="1"
                  :max="999999"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="24">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="checkList" icon="search">查询</a-button>
                <a-button type="default" @click="reset" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table
          :data-source="tableData"
          :columns="columns"
          :loading="tableLoading"
          :rowKey="(record) => record.id"
          :pagination="page"
          bordered
          @change="changeTable"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
          <span slot="price" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="sellPrice" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="imgUrl" slot-scope="item">
            <ZoomMedia style="height: 50px" :value="item"></ZoomMedia>
          </span>

          <span slot="saleQuantity" slot-scope="text,row"> {{ row.saleQuantity - row.orderOccupy}} </span>
        </a-table>
      </div>
      <div class="footer-btns">
        <a-button @click="onCancel">关闭</a-button>
        <a-button type="primary" :loading="btnsLoading" @click="onSubmit(1)">选中</a-button>
        <a-button type="primary" :loading="btnsLoading1" @click="onSubmit(2)">选中并关闭</a-button>
      </div>
      <!--      <ConfirmProduct ref="ConfirmProduct" :dataList="dataList" :dealerId="dealerId"></ConfirmProduct>-->
    </a-modal>
  </div>
</template>

<script>
import ConfirmProduct from './ConfirmProduct.vue'
export default {
  components: {
    ConfirmProduct,
  },
  data() {
    return {
      visible: false,
      tableLoading: false,
      btnsLoading: false,
      btnsLoading1: false,
      tableData: [],
      handle: '',
      searchData: {},
      columns: [
        {
          title: '商品图片',
          dataIndex: 'imgUrl',
          align: 'center',
          scopedSlots: { customRender: 'imgUrl' },
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          // width: 200,
          // ellipsis: true,
          align: 'center',
        },
        {
          title: '品牌',
          dataIndex: 'brandName',
          // width: 120,
          // ellipsis: true,
          align: 'center',
        },
        {
          title: '分类',
          dataIndex: 'categoryName',
          // width: 120,
          // ellipsis: true,
          align: 'center',
        },
        {
          title: '规格',
          dataIndex: 'specStr',
          // width: 120,
          // ellipsis: true,
          align: 'center',
        },
        {
          title: '型号',
          dataIndex: 'barCode',
          // width: 120,
          // ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '建议零售价',
        //   dataIndex: 'price',
        //   // width: 120,
        //   ellipsis: true,
        //   align: 'center',
        //   scopedSlots: { customRender: 'price' },
        // },

        {
          title: '标准开票价（D价）',
          dataIndex: 'sellPrice',
          // width: 120,
          // ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'sellPrice' },
        },
        {
          title: '单位',
          dataIndex: 'proUnitName',
          // width: 120,
          // ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '库存',
        //   dataIndex: 'saleQuantity',
        //   // width: 120,
        //   ellipsis: true,
        //   align: 'center',
        //   scopedSlots: { customRender: 'saleQuantity' }
        // },
      ],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      dealerId: '',
      orderCode:'',
      dataList: [],
      current: 0,
      policyList: [],
      policyKeysList: [],
      list: [],
      categoryList: [],
      categorySelectData: [],
      proBrandList: [],
    }
  },
  created() {
    this.axios.get('/api/product/category/categoryList?flagEnable=true').then((res) => {
      this.categoryList = res.body[0] ? res.body[0].children : []
    })
    this.axios.get('/api/product/system/brand/All/list').then((res) => {
      this.proBrandList = res.body
    })
  },
  methods: {
    onCategoryChange(value) {
      this.categorySelectData = value
      var reg = new RegExp(',', 'g')
      this.searchData.categoryIdStr = value
        .map((i) => {
          return i
        })
        .toString()
        .replace(reg, '_')
    },
    // 打开弹框时触发
    isShow(id,orderCode, list) {
      this.visible = true
      this.btnsLoading = false
      this.dealerId = id
      this.orderCode = orderCode
      this.list = list
      this.searchData = {}
      this.categorySelectData=[]
      this.getList(0)
    },
    checkList(){
      this.getList(0)
    },

    reset(){
      this.page.current = 1
      this.page.pageSize =10
      this.getList(1)
    },

    // 获取数据源
    getList(num) {
      this.tableLoading = true

      if (num === 1) {
        this.searchData = {}
        this.categorySelectData = []
      }
      const data = Object.assign(
        {},
        {
          pageNumber: this.page.current,
          pageSize: this.page.pageSize,
          BuyCustomerId: this.dealerId,
          ...this.searchData,
        }
      )
      this.axios.get('/api/product/system/goods/listByOrder', { params: data }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.body.records
          this.page.total = res.body.total
          this.tableLoading = false
          this.selectedRows=[]
          this.selectedRowKeys = []
        }
      })
    },
    // 关闭弹框
    onCancel() {
      this.visible = false
      this.btnsLoading = false
      this.btnsLoading1 = false
    },

    changeTable(pagination) {
      this.page = pagination
      // this.getList(1)
      this.checkList()
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    // 获取政策列表
    onSubmit(num) {
      let ids = []
      if (this.list.length > 0) {
        let item = this.list.map((e) => e.goodsId)
        ids = this.selectedRowKeys.filter((i) => !item.some((e) => e === i))
      }
      let tmpIds = ids.concat(this.selectedRowKeys)
      let res = new Map()
      ids = tmpIds.filter((e) => !res.has(e) && res.set(e, 1))
      let data = {
        dealerId: this.dealerId,
        goodsIds: ids,
        orderCode: this.orderCode
      }
      if (this.selectedRowKeys.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      // if(data.goodsIds.length <= 0) {
      //   return this.$message.warning('当前商品已添加')
      // }
      if(num ===1){
        this.btnsLoading = true
      }else if(num ===2){
        this.btnsLoading1 = true
      }
      // this.btnsLoading = true
      this.axios
        .post(`/api/order/order/orderInfo/queryProListAndPolicy`, data)
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.body
            if(num === 2){
              this.visible = false
            }
            this.$emit('get-product-list', this.dataList)
            this.btnsLoading = false
            this.btnsLoading1 = false
          }
        })
        .catch((err) => {
          this.btnsLoading = false
          this.btnsLoading1 = false
        })
    },
  },
}
</script>

<style scoped>
.steps-action {
  margin-top: 20px;
  text-align: right;
}
.steps-content {
  margin-top: 60px;
}
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>